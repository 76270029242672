import React from 'react'
import { t } from 'i18next'
import { DateTime } from 'luxon'

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { PaymentTransaction } from '@components/ReferralBot/Type/PaymentTransaction'
import { PaymentType } from '@components/ReferralBot/Type/PaymentType'
import { red, green } from '@mui/material/colors'
import Box from '@mui/material/Box'

interface Props {
  rows: PaymentTransaction[]
}

export const Transactions = ({ rows }: Props) => {
  const isWithdraw = (t: PaymentTransaction) => t.type === PaymentType.WITHDRAW

  const getReason = (transaction: PaymentTransaction) => {
    switch (transaction.type) {
      case PaymentType.WITHDRAW:
        return t('referralBot.paymentBalance.withdrawToWallet', { wallet: transaction.withdrawWallet })
      case PaymentType.DEPOSIT_FROM_REFERRER:
        return t('referralBot.paymentBalance.depositFromCampaign', { campaignName: transaction.campaignName })
      default:
        return 'Unknown'
    }
  }

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper}>
        <Table
          aria-label="custom pagination table"
          sx={{ tableLayout: 'fixed' }}
        >
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.createdAt.toString()}>
                <TableCell
                  width="100px"
                  sx={{ color: isWithdraw(row) ? red[500] : green[500] }}
                >
                  {isWithdraw(row) ? '-' : '+'}
                  {row.amount}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                  align="left"
                >
                  {getReason(row)}
                </TableCell>
                <TableCell
                  width="130px"
                  align="left"
                >
                  {DateTime.fromISO(row.createdAt).toLocaleString(DateTime.DATETIME_MED)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
