import { ReactRouter } from '@tanstack/react-router'
import { rootRoute, rootRouteIndex } from '@ui/routing/RootRouteIndex'
import { createGreetingRoute } from '@ui/components/Greeting/Create/router'
import { viewGreetingRoute } from '@ui/components/Greeting/View/router'
import { referralBotRoute } from '@components/ReferralBot/router'

const routes = [
  rootRouteIndex,
  createGreetingRoute,
  viewGreetingRoute,
  referralBotRoute,
  // ownCampaignListBotRoute,
  // joinedCampaignListBotRoute,
  // ownCampaignAddBotRoute,
]

const routeTree = rootRoute.addChildren(routes)

export const router = new ReactRouter({ routeTree })

// Register your router for maximum type safety
declare module '@tanstack/react-router' {
  interface Register {
    // @ts-ignore
    router: typeof router
  }
}
