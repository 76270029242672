import React from 'react'
import { t } from 'i18next'

import { Grid } from '@mui/material'

export const NoFreeGreetings = () => {
  return (
    <Grid
      container
      sx={{ pt: 5 }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        {t('noFreeGreetings')}
      </Grid>
    </Grid>
  )
}
