import { t } from 'i18next'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { useAtom } from 'jotai/index'
import { useSnackbar } from 'notistack'

import Box from '@mui/material/Box'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchUserProfile, withdrawBalance } from '@framework/api/referral-bot.api'
import { Alert, Button, Grid, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { LinkButton } from '@components/LinkButton'
import { paymentBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TopActions } from '@components/ReferralBot/TopActions'

const validationSchema = z.object({
  walletAddress: z.string().min(1).max(100), // todo check TON valid address
})

export type WithdrawPayload = z.infer<typeof validationSchema>

export const WithdrawBalance = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.paymentBalanceWithdraw.title') || '')

  // todo replace it with a list query
  const { data: userProfile, isLoading: isLoadingProfile } = useQuery({
    queryKey: ['user_profile'],
    queryFn: () => fetchUserProfile(),
  })

  const { mutate: withdrawBalanceMutate, isLoading: isWithdrawing } = useMutation({
    // mutationKey: ['campaigns', uuid],
    mutationFn: withdrawBalance,
  })

  const defaultData: WithdrawPayload = {
    walletAddress: '',
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<WithdrawPayload>({
    defaultValues: defaultData,
    resolver: zodResolver(validationSchema),
  })

  const onSave = (payload: WithdrawPayload) => {
    console.log('----> ', payload)

    withdrawBalanceMutate(
      { ...payload },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('referralBot.paymentBalanceWithdraw.successfullyWithdrawn'), { variant: 'success' })

          await queryClient.invalidateQueries(['user_profile'])
        },
        onError: async (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
        },
      }
    )
  }

  const tooLowBalance = userProfile && Number(userProfile.availableBalance) < Number(userProfile.minWithdrawAmount)
  const disableActions = isLoadingProfile || isWithdrawing || tooLowBalance

  return (
    <Box>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={paymentBalanceBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {tooLowBalance && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert severity="warning">{t('referralBot.paymentBalanceWithdraw.lowBalance')}</Alert>
        </Box>
      )}
      <form
        onSubmit={handleSubmit(onSave)}
        id="create-own-campaign"
      >
        <Grid
          container
          sx={{ pt: 5 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Controller
              name="walletAddress"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="walletAddress"
                  label={t('referralBot.paymentBalanceWithdraw.walletAddress')}
                  variant="outlined"
                  error={!!errors.walletAddress}
                  helperText={String(errors.walletAddress?.message || '')}
                  disabled={disableActions}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ textAlign: 'center' }}
          >
            <LoadingButton
              loading={isWithdrawing}
              disabled={disableActions}
              loadingPosition="start"
              startIcon={<ArrowUpwardIcon />}
              variant="outlined"
              type="submit"
            >
              {t(`referralBot.paymentBalanceWithdraw.submitButton`, { amount: userProfile?.availableBalance })}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
