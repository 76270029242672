export const canWithdrawReward = (referrerBalance: string, campaignFinishedAt: string | null, rewardWithdrewAt: string | null) => {
  if (!campaignFinishedAt) {
    return false
  }

  if (Number(referrerBalance) <= 0) {
    return false
  }

  if (rewardWithdrewAt) {
    return false
  }

  return true
}
