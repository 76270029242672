import React, { useState } from 'react'
import { t } from 'i18next'

import { useParams } from '@tanstack/react-router'
import { viewGreetingRoute } from '@ui/components/Greeting/View/router'
import { Alert, Box, CircularProgress, Container, useMediaQuery, useTheme } from '@mui/material'
import { Background } from '@components/Greeting/View/Background'
import { useQuery } from '@tanstack/react-query'
import { fetchGreeting } from '@framework/api/greeting.api'
import { Greeting } from '@components/Greeting/View/Greeting'
import { WritingProgressBar } from '@components/Greeting/View/WritingProgressBar'

export const ViewGreetingContainer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // eslint-disable-next-line
  const { uuid } = useParams({ from: viewGreetingRoute['$uuid'] })
  const [intervalMs, setIntervalMs] = useState<number>(5000)
  const { data, isLoading } = useQuery({
    queryKey: ['greetings', uuid],
    queryFn: () => fetchGreeting(uuid),
    refetchInterval: intervalMs > 0 ? intervalMs : false,
  })

  const isError = data && data.isError
  const hasGeneratedText = Boolean(data?.generatedText)
  const disableInterval = intervalMs && (hasGeneratedText || isError)

  if (disableInterval) {
    console.debug('Disable the refetch interval.')
    setIntervalMs(0)
  }

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ position: 'relative', zIndex: 2, p: isMobile ? 2 : 0 }}
      >
        {data && !isError && !hasGeneratedText && <WritingProgressBar />}
        {data && hasGeneratedText && <Greeting greeting={data} />}
        {data && isError && <Alert severity="error">{t('viewPage.error')}</Alert>}

        {!data && (
          <CircularProgress
            size={100}
            sx={{
              zIndex: 2,
              position: 'fixed',
              top: '50%',
              left: '50%',
              marginTop: '-50px',
              marginLeft: '-50px',
            }}
            color="secondary"
          />
        )}
      </Container>
      <Box
        sx={{
          textAlign: 'center',
          background: 'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(99,9,121,1) 35%, rgba(0,212,255,1) 100%)',
          width: '100%',
          height: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'top',
          zIndex: 0,
        }}
      >
        {data && hasGeneratedText && <Background />}
      </Box>
    </>
  )
}
