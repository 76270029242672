import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchReferrer, withdrawReferrerReward } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import Box from '@mui/material/Box'
import { CustomTable } from '@components/ReferralBot/Table/CustomTable'
import { CustomTableRow } from '@components/ReferralBot/Type/CustomTableRow'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import CheckIcon from '@mui/icons-material/Check'
import { paymentBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import { joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'

export const WithdrawReward = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const queryClient = useQueryClient()
  const { uuid } = useParams()
  const tableRows: CustomTableRow[] = []

  const { data: referrer, isLoading } = useQuery({
    queryKey: ['referrer', uuid],
    queryFn: () => fetchReferrer(uuid),
  })
  const { mutate: withdrawReferrerRewardMutate, isLoading: isWithdrawing } = useMutation({
    mutationFn: withdrawReferrerReward,
  })

  const onWithdrawClick = () => {
    withdrawReferrerRewardMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.withdrawReward.successfullyWithdrawn'), { variant: 'success' })

        await queryClient.invalidateQueries(['referrer', uuid])
        await queryClient.invalidateQueries(['user_profile'])
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  if (referrer) {
    tableRows.push({
      label: t('referralBot.withdrawReward.joinedAt'),
      val: referrer.joinedAt.toString(),
    })
    tableRows.push({
      label: t('referralBot.withdrawReward.numberOfReferrals'),
      val: referrer.numberOfReferrals.toString(),
    })
    tableRows.push({
      label: t('referralBot.withdrawReward.reward'),
      val: referrer.reward.toString(),
    })
  }

  useEffect(() => {
    setTitle(t('referralBot.withdrawReward.title') || '')

    return () => setTitle('')
  }, [])

  const alreadyWithdrawn = Boolean(referrer?.rewardWithdrewAt)

  const disableWithdrawButton = isLoading || isWithdrawing

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={joinedCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {alreadyWithdrawn && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.withdrawReward.rewardWasWithdrawnToYourBalance')}
            <Button
              component={LinkButton}
              to={paymentBalanceBotRoute.id}
              variant="text"
              color="primary"
            >
              {t('referralBot.withdrawReward.seeMyBalance')}
            </Button>
          </Alert>
        </Box>
      )}
      {tableRows.length > 0 && <CustomTable rows={tableRows} />}
      <p>{t('referralBot.withdrawReward.description')}</p>
      {!alreadyWithdrawn && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
          p={3}
        >
          <Button
            startIcon={<KeyboardDoubleArrowUpIcon />}
            variant="outlined"
            color="secondary"
            onClick={onWithdrawClick}
            disabled={disableWithdrawButton}
          >
            {t('referralBot.withdrawReward.withdrawAction', { amount: referrer?.reward.toString() || '' })}
          </Button>
        </Box>
      )}
    </>
  )
}
