import { Box } from '@mui/material'
import { CONTACT_EMAIL } from '@framework/constants'

export const Footer = () => {
  return (
    <Box
      id="footer"
      sx={{
        position: 'relative',
        zIndex: 1,
        textAlign: 'center',
        color: '#fff',
        pt: 4,
        opacity: 0.9,
      }}
    >
      {CONTACT_EMAIL}
    </Box>
  )
}
