import { Route } from '@tanstack/react-router'
import { Balance } from '@components/ReferralBot/Payment/Balance'
import { WithdrawBalance } from '@components/ReferralBot/Payment/WithdrawBalance'

import { referralBotRoute } from '../router'

export const paymentBalanceBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/balance',
  component: Balance,
})

export const paymentWithdrawBalanceBotRoute = new Route({
  getParentRoute: () => referralBotRoute,
  path: '/withdraw-balance',
  component: WithdrawBalance,
})
