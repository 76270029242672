import axios from 'axios'

import { API_PREFIX } from '@framework/constants'

declare global {}

function errorHandler(error: any) {
  return Promise.reject(error)
}

function requestHandler(request: any) {
  return request
}

// init axios instance
export const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(requestHandler, errorHandler)

// api methods
const get = async <T>(url: string, params: any = {}, headers?: any): Promise<T> => {
  const response = await axiosInstance.get(`${API_PREFIX}${url}`, {
    params,
    headers,
    withCredentials: true,
  })
  return response.data
}

const post = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
  const response = await axiosInstance.post(`${API_PREFIX}${url}`, data, {
    ...config,
    withCredentials: true,
  })
  return response.data
}

const update = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
  const response = await axiosInstance.put(`${API_PREFIX}${url}`, data, {
    ...config,
    withCredentials: true,
  })
  return response.data
}

const patch = async <T>(url: string, data?: any, config: any = {}): Promise<T> => {
  const response = await axiosInstance.patch(`${API_PREFIX}${url}`, data, {
    ...config,
    withCredentials: true,
  })
  return response.data
}

const remove = async <T>(url: string, config: any = {}): Promise<T> => {
  const response = await axiosInstance.delete(`${API_PREFIX}${url}`, {
    ...config,
    withCredentials: true,
  })
  return response.data
}

export { get, update, remove, post, patch }
