import { t } from 'i18next'
import React from 'react'

import { Box } from '@mui/material'

export const WritingProgressBar = () => {
  const imgUrl = new URL('./writing-hand.gif', import.meta.url).href

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          m: 10,
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            width: '100px',
            height: '100px',
            background: `url(${imgUrl}) center center no-repeat`,
            backgroundSize: 'contain',
            display: 'inline-block',
            filter: 'grayscale(100%)',
          }}
        >
          {' '}
        </Box>
        <Box
          sx={{
            p: 2,
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {t('viewPage.waitTillWillBeGenerated')}
        </Box>
      </Box>
    </Box>
  )
}
