import { deDE, enUS } from '@mui/material/locale'

export const LANGUAGES = {
  EN: 'en',
} as const

export const LOCALES = {
  [LANGUAGES.EN]: 'en-GB',
} as const

export const LOCALIZATIONS = {
  [LANGUAGES.EN]: enUS,
} as const
