import React, { useCallback } from 'react'
import type { Container, Engine } from 'tsparticles-engine'
import Particles from 'react-particles'
// import { loadFull } from 'tsparticles'
// import { loadFireworksPreset } from 'tsparticles-preset-fireworks'
import { loadConfettiPreset } from 'tsparticles-preset-confetti'

export const Background = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine)

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    // await loadFull(engine)
    // await loadFireworksPreset(engine)
    await loadConfettiPreset(engine)
  }, [])

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container)
  }, [])

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        preset: 'confetti',
        emitters: [
          {
            life: {
              duration: 0,
              count: 1,
            },
            position: {
              x: 0,
              y: 30,
            },
            particles: {
              move: {
                direction: 'top-right',
              },
            },
          },
          {
            life: {
              duration: 0,
              count: 1,
            },
            position: {
              x: 100,
              y: 30,
            },
            particles: {
              move: {
                direction: 'top-left',
              },
            },
          },
        ],
      }}
    />
  )
}
