import React from 'react'

import { Box, CircularProgress } from '@mui/material'

export const Loader = () => {
  return (
    <Box sx={{ height: '300px', width: '100%' }}>
      <CircularProgress
        size={100}
        sx={{
          zIndex: 2,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-50px',
          marginLeft: '-50px',
        }}
        color="secondary"
      />
    </Box>
  )
}
