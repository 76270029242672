import { t } from 'i18next'
import React from 'react'

import Box from '@mui/material/Box'
import { Alert } from '@mui/material'

interface Props {
  text: string
  severity?: 'success' | 'info' | 'warning' | 'error'
}

export const AlertMessage = ({ text, severity }: Props) => {
  return (
    <Box
      mt={2}
      mb={2}
    >
      <Alert severity={severity || 'info'}>{text}</Alert>
    </Box>
  )
}
