import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { Form, NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCampaign } from '@framework/api/referral-bot.api'
import { useNavigate } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getErrorMessage } from '@components/ReferralBot/helpers'

export const Add = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate, isLoading: isCreating } = useMutation({
    mutationFn: createCampaign,
  })
  const navigate = useNavigate()
  const onSave = (payload: NewCampaignType) => {
    mutate(payload, {
      onSuccess: async (data) => {
        enqueueSnackbar(t('referralBot.newOwnCampaignForm.created'), { variant: 'success' })

        await queryClient.invalidateQueries(['campaigns'])
        await navigate({ to: ownCampaignListBotRoute.id })
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      <Form
        loading={isCreating}
        onSave={onSave}
      />
    </>
  )
}
