import { useHydrateAtoms } from 'jotai/react/utils'
import { queryClientAtom } from 'jotai-tanstack-query'

import { QueryClient } from '@tanstack/query-core'

export const queryConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    },
  },
}

export const queryClient = new QueryClient(queryConfig)

export function HydrateAtoms({ children }) {
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return children
}
