import React from 'react'
import { t } from 'i18next'

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

interface Props {
  handleConfirm: () => void
  handleCancel: () => void
  title?: string
  text?: string
}

export const ConfirmDialog = ({ handleConfirm, handleCancel, text, title }: Props) => {
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || t('referralBot.confirmDialog.title')}</DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel}>{t('referralBot.confirmDialog.cancel')}</Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          color="warning"
        >
          {t('referralBot.confirmDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
