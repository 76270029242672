import React from 'react'

import { Link as RouterLink } from '@tanstack/react-router'

export const LinkButton = React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
  <RouterLink
    ref={ref}
    {...props}
  />
))
