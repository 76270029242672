import React from 'react'
import { useAtom } from 'jotai/index'
import { t } from 'i18next'

import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { useQuery } from '@tanstack/react-query'
import { fetchReferrers } from '@framework/api/referral-bot.api'
import { ListAsTable } from '@components/ReferralBot/JoinedCampaign/ListAsTable'
import { AlertMessage } from '@components/ReferralBot/Alert'

export const List = () => {
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.joinedCampaigns.title') || '')

  const { data, isLoading } = useQuery({
    queryKey: ['joined_campaigns'],
    queryFn: () => fetchReferrers(),
  })

  const campaigns = data || []

  return (
    <>
      {!isLoading && campaigns.length === 0 && <AlertMessage text={t('referralBot.joinedCampaigns.noCampaigns')} />}
      {campaigns.length > 0 && <ListAsTable campaigns={campaigns} />}
    </>
  )
}
