import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { LANGUAGES } from '@framework/constants/languages'

import en from './en.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: [LANGUAGES.EN],
    fallbackLng: LANGUAGES.EN,
    debug: import.meta.env.DEV,
    detection: {
      order: ['querystring'],
      caches: ['localStorage'],
    },
    resources: {
      en: {
        translation: en,
      },
    },
    react: { useSuspense: true },
  })

i18n.languages = [LANGUAGES.EN]

export default i18n
