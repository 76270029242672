import { t } from 'i18next'
import React from 'react'
import { useSnackbar } from 'notistack'

import { LocationForm, NewGreetingType } from '@components/Greeting/Create/Form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createGreeting } from '@framework/api/greeting.api'
import { Box, Container, Grid, Typography, InputLabel, CircularProgress } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { viewGreetingRoute } from '@ui/components/Greeting/View/router'
import { fetchSettings } from '@framework/api/settings.api'
import { Loader } from '@components/Greeting/Create/Loader'
import { NoFreeGreetings } from '@components/Greeting/Create/NoFreeGreetings'

export const CreateGreetingContainer = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { mutate, isLoading: isCreating } = useMutation({
    mutationFn: createGreeting,
  })
  const { data: settings } = useQuery({
    queryKey: ['settings'],
    queryFn: fetchSettings,
  })

  const onSave = (newGreeting: NewGreetingType) => {
    mutate(newGreeting, {
      onSuccess: async (data) => {
        const { uuid } = data

        await navigate({ to: viewGreetingRoute.path, params: { uuid } })
      },
      onError: (error) => {
        console.error(error)

        const message = error && typeof error === 'object' && 'message' in error ? `${error.message}` : t('error')

        enqueueSnackbar(message, { variant: 'error' })
      },
    })
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        justifyContent="center"
      >
        <Grid item>
          <Box
            sx={{
              position: 'relative',
              maxWidth: '600px',
            }}
          >
            <Box
              sx={{
                background: '#fff',
                p: 3,
                borderRadius: '3px',
                m: 3,
                position: 'relative',
                zIndex: 2,
                display: 'inline-block', // tod preved horizontal margin collapse
              }}
            >
              <Typography
                align="center"
                variant="h5"
              >
                {t('createNewGreetingLabel')}
              </Typography>
              <InputLabel sx={{ textAlign: 'center', whiteSpace: 'pre-line', pt: 1 }}>{t('createNewGreetingLabel2')}</InputLabel>
              {!settings && <Loader />}
              {settings && settings.availableFreeGreetings > 0 && (
                <LocationForm
                  loading={isCreating}
                  onSave={onSave}
                />
              )}
              {settings && !settings.availableFreeGreetings && <NoFreeGreetings />}
            </Box>
            <Box
              sx={{
                background: '#fff',
                borderRadius: '3px',
                opacity: 0.5,
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
