import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai/index'
import { useSnackbar } from 'notistack'

import { useParams } from '@tanstack/react-router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { joinedCampaignListBotRoute, joinedCampaignWithdrawRewardRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchReferrerDetails, withdrawReferrerReward } from '@framework/api/referral-bot.api'
import { CustomTable } from '@components/ReferralBot/Table/CustomTable'
import { referrerDetailsToTableRows } from '@components/ReferralBot/helpers/custom-table.helper'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import { canWithdrawReward } from '@components/ReferralBot/helpers/referrer.helper'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

export const Details = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { uuid } = useParams()

  const { data: referrerDetails, isLoading: isFetchingReferrerDetails } = useQuery({
    queryKey: ['referrer_details', uuid],
    queryFn: () => fetchReferrerDetails(uuid),
  })

  const { mutate: withdrawReferrerRewardMutate, isLoading: isWithdrawing } = useMutation({
    mutationFn: withdrawReferrerReward,
  })

  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.joinedCampaignDetails.title', { campaignName: referrerDetails?.campaignName }) || '')

  const alreadyWithdrawn = referrerDetails && Boolean(referrerDetails?.rewardWithdrewAt)
  const showWithdrawButton =
    referrerDetails && canWithdrawReward(referrerDetails.reward, referrerDetails.campaignFinishedAt, referrerDetails.rewardWithdrewAt)

  const onWithdrawClick = () => {
    withdrawReferrerRewardMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.withdrawReward.successfullyWithdrawn'), { variant: 'success' })

        await queryClient.invalidateQueries(['referrer', uuid])
        await queryClient.invalidateQueries(['user_profile'])
        await queryClient.invalidateQueries(['referrer_details'])
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={joinedCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {referrerDetails && <CustomTable rows={referrerDetailsToTableRows(referrerDetails)} />}
      {alreadyWithdrawn && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.withdrawReward.rewardWasWithdrawnToYourBalance')}
          </Alert>
        </Box>
      )}
      {showWithdrawButton && (
        <>
          <Box sx={{ pt: 4, pb: 2 }}>
            <Alert severity="info">{t('referralBot.withdrawReward.description')}</Alert>
          </Box>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent="center"
          >
            <Button
              startIcon={<KeyboardDoubleArrowUpIcon />}
              variant="outlined"
              color="secondary"
              onClick={onWithdrawClick}
              disabled={isWithdrawing || isFetchingReferrerDetails}
            >
              {t('referralBot.withdrawReward.withdrawAction', { amount: referrerDetails?.reward.toString() || '' })}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
