import { NewGreetingType } from '@components/Greeting/Create/Form'
import { ViewGreeting } from '@framework/types/response/Greeting/ViewGreeting'

import { get, post } from './axios'

export const createGreeting = async (greeting: NewGreetingType): Promise<{ uuid: string }> => {
  // todo add typeguard
  return (await post('/api/greetings', greeting)) as { uuid: string }
}

export const fetchGreeting = async (uuid: string): Promise<ViewGreeting> => {
  const res = await get<ViewGreeting>(`/api/greetings/${uuid}`)
  // todo handle error
  return res
}
