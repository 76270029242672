import React from 'react'

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { HandWriting } from '@components/Greeting/View/HandWriting'
import { ViewGreeting } from '@framework/types/response/Greeting/ViewGreeting'

interface Props {
  greeting: ViewGreeting
}

export const Greeting = ({ greeting }: Props) => {
  const { generatedText } = greeting
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Grid
        item
        xs={12}
      >
        <Box
          sx={{
            padding: isMobile ? 0 : 2,
            border: '6px solid rgb(255, 255, 255, 0.5)',
            borderRadius: '3px',
          }}
        >
          <HandWriting
            text={generatedText || ''}
            fontSize={isMobile ? 40 : 50}
          />
        </Box>
      </Grid>
      <Box
        sx={{
          // boxShadow: '0px 0px 5px 5px rgba(66, 68, 90, 1)',
          boxShadow: '0px 0px 40px 40px rgba(66, 68, 90, 1)',
          background: '#0c012d14',
          borderRadius: '3px',
          opacity: 0.5,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
    </Grid>
  )
}
