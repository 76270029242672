import * as React from 'react'
import { useAtomValue } from 'jotai'
import { t } from 'i18next'

import { Link, Outlet } from '@tanstack/react-router'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CampaignIcon from '@mui/icons-material/Campaign'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { joinedCampaignListBotRoute } from '@components/ReferralBot/JoinedCampaign/router'
import { useIsFetching } from '@tanstack/react-query'
import LinearProgress from '@mui/material/LinearProgress'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { BalanceHeader } from '@components/ReferralBot/BalanceHeader'
import GroupIcon from '@mui/icons-material/Group'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export function MainContainer() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const title = useAtomValue(headerTitleAtom)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const isFetching = useIsFetching()

  return (
    <Box
      sx={{ display: 'flex', width: '100%' }}
      className="ref-bot"
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        className="app-bar"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ width: '100%' }}
          >
            {isFetching ? <LinearProgress /> : title}
          </Typography>
          <BalanceHeader />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            // @ts-ignore
            button
            component={Link}
            to={joinedCampaignListBotRoute.id}
            onClick={handleDrawerClose}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: 'left' }}
              primary={t('referralBot.joinedCampaigns.title')}
            />
          </ListItem>
          <ListItem
            // @ts-ignore
            button
            component={Link}
            to={ownCampaignListBotRoute.id}
            onClick={handleDrawerClose}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary={t('referralBot.ownCampaigns.title')} />
          </ListItem>
        </List>
      </Drawer>
      <Main
        open={open}
        sx={{ pl: 0.5, pr: 0.5 }}
      >
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  )
}
