import { t } from 'i18next'

import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'

export const formatCurrency = (amout: string): string => `${amout} ${CURRENCY_SYMBOL}`

export const accessInnerProperty = (obj: NonNullable<unknown>, propertyPath: string) => {
  const properties = propertyPath.split('.')
  let currentObj = obj

  for (let i = 0; i < properties.length; i++) {
    if (currentObj[properties[i]] !== undefined) {
      currentObj = currentObj[properties[i]]
    } else {
      return undefined
    }
  }

  return currentObj
}

export const getErrorMessage = (error: unknown) => {
  console.error(error)
  let message = error && typeof error === 'object' && 'message' in error ? `${error.message}` : t('error')

  if (error && typeof error === 'object') {
    const certainMessage = accessInnerProperty(error, 'response.data.message')

    if (certainMessage) {
      message = String(certainMessage)
    }
  }

  return message
}
