import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

export const HandWriting = ({ text, fontSize }: { text: string; fontSize: number }) => {
  const countCalls = 0
  const write = (ctx: CanvasRenderingContext2D, getOnlyRowsCallback: Function | undefined = undefined) => {
    const canvasWidth = ctx.canvas.width

    if (!canvasWidth) {
      console.warn('Render is not possible! Canvas width is zero!')

      return
    }

    // dash-length for off-range
    const dashLen = 220

    // we'll update this, initialize
    let dashOffset = dashLen

    // some arbitrary speed
    const speed = 50 // 20

    // the text we will draw
    const txt = ` ${text}` // 'Привіт! enter the sunshine state'

    // start position for x and iterator
    let x = 0
    let i = 0
    let rowNumber = 1
    const fontLineHeight = fontSize + 10
    let y = fontSize

    // Comic Sans?? Let's make it useful for something ;) w/ fallbacks
    ctx.font = `${fontSize}px Marck Script`

    // thickness of the line
    ctx.lineWidth = 1

    // to avoid spikes we can join each line with a round joint
    ctx.lineJoin = 'round'

    // increase realism letting background (f.ex. paper) show through
    /* ctx.globalAlpha = 2/3; */
    ctx.globalAlpha = 1

    // some color, lets use a black pencil
    ctx.strokeStyle = ctx.fillStyle = '#FFF'
    const writing = () => {
      // clear canvas for each frame
      ctx.clearRect(x, y, 60, 150)

      // calculate and set current line-dash for this char
      ctx.setLineDash([dashLen - dashOffset, dashOffset - speed])

      // reduce length of off-dash
      dashOffset -= speed

      if (!getOnlyRowsCallback) {
        // draw char to canvas with current dash-length
        ctx.strokeText(txt[i], x, y)
      }

      // char done? no, the loop
      if (dashOffset > 0 && !getOnlyRowsCallback) {
        requestAnimationFrame(writing)
      } else {
        let newLine = x > canvasWidth
        // todo replace multiple lines?
        if (txt[i] === ' ') {
          let nextSpaceIndex = txt.indexOf(' ', i + 1)
          // end of string
          // set last symbol position
          if (nextSpaceIndex === -1) {
            nextSpaceIndex = text.length
          }

          let neededSpace = ctx.measureText(' ').width

          for (let j = i; j <= nextSpaceIndex; j++) {
            neededSpace += ctx.measureText(txt[j]).width
          }

          console.debug(`x: ${x}, neededSpace: ${neededSpace}, next symbol: ${txt[i + 1]}`)

          if (x + neededSpace > canvasWidth) {
            newLine = true
          }
        } else if (text[i] === '\n\n') {
          newLine = true
        }

        if (newLine) {
          rowNumber += 1
          y = fontLineHeight * rowNumber
          x = 0

          console.debug(`Line: ${rowNumber}`)
        }

        if (!getOnlyRowsCallback) {
          // ok, outline done, lets fill its interior before next
          ctx.fillText(txt[i], x, y)
        }

        // reset line-dash length
        dashOffset = dashLen

        // get x position to next char by measuring what we have drawn
        // notice we offset it a little by random to increase realism
        x += ctx.measureText(txt[i++]).width + ctx.lineWidth * Math.random()

        // lets use an absolute transform to randomize y-position a little
        ctx.setTransform(1, 0, 0, 1, 0, 3)

        // and just cause we can, rotate it a little too to make it even
        // more realistic
        ctx.rotate(Math.random() * 0.005)

        // if we still have chars left, loop animation again for this char
        if (i < txt.length) {
          if (!getOnlyRowsCallback) {
            requestAnimationFrame(writing)
          } else {
            writing()
          }
        } else if (getOnlyRowsCallback) {
          getOnlyRowsCallback(rowNumber, fontLineHeight)
        }
      }

      return rowNumber
    }
    writing()
  }
  const ref = useRef(null)
  const refCanvas = useRef(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const { offsetWidth } = ref.current as HTMLDivElement
    setWidth(offsetWidth)
  }, [ref.current])

  useEffect(() => {
    if (refCanvas.current && width > 0) {
      const canvas = document.querySelector('#hand-writing-canvas')

      if (!canvas) {
        throw new Error('Canvas not found.')
      }

      const ctx = (canvas as HTMLCanvasElement).getContext('2d')

      if (!ctx) {
        throw new Error('ctx not found.')
      }

      const temporaryCanvas = document.createElement('canvas')
      let temporaryContext = temporaryCanvas.getContext('2d')
      temporaryCanvas.width = width

      if (!temporaryContext) {
        throw new Error('Can not create 2d context!')
      }

      temporaryContext.canvas.width = width

      console.debug('Write temporary context.')
      write(temporaryContext, (rowsNumber, fontLineHeight) => {
        temporaryContext = null
        // also add the first line to the height
        // and the delta that should compensate the margin that shows realism
        const delta = 0.3
        const borderWidth = 6
        ctx.canvas.height = rowsNumber * fontLineHeight + fontLineHeight + rowsNumber * delta + borderWidth * 2
        console.debug(`Rows: ${rowsNumber}, Line Height: ${fontLineHeight}. Write permanent context.`)
        write(ctx)
      })
    }
  }, [refCanvas.current, width])

  return (
    <div ref={ref}>
      <canvas
        ref={refCanvas}
        id="hand-writing-canvas"
        // style={{ border: '1px solid red' }}
        height="500"
        width={width}
      />
    </div>
  )
}
