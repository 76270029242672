import { t } from 'i18next'
import { DateTime } from 'luxon'

import { CustomTableRow } from '@components/ReferralBot/Type/CustomTableRow'
import { ReferrerDetails } from '@components/ReferralBot/Type/ReferrerDetails'
import { statusToTranslatableKey } from '@components/ReferralBot/helpers/CampaignStatus'
import { formatDateTime } from '@components/ReferralBot/helpers/date.helper'

export const referrerDetailsToTableRows = (details: ReferrerDetails): CustomTableRow[] => {
  const tableRows: CustomTableRow[] = []

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.campaignName'),
    val: details.campaignName,
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.campaignStatus'),
    val: t(statusToTranslatableKey(details.campaignStatus)),
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.campaignRewardPerUser'),
    val: `${details.campaignRewardPerUser}`,
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.campaignStartedAt'),
    val: formatDateTime(details.campaignStartedAt),
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.campaignFinishedAt'),
    val: formatDateTime(details.campaignFinishedAt),
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.joinedAt'),
    val: formatDateTime(details.joinedAt),
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.numberOfReferrals'),
    val: `${details.numberOfReferrals}`,
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.referralLink'),
    val: details.referralLink || '',
    isLink: true,
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.reward'),
    val: `${details.reward}`,
  })

  tableRows.push({
    label: t('referralBot.joinedCampaignDetails.rewardWithdrewAt'),
    val: formatDateTime(details.rewardWithdrewAt),
  })

  return tableRows
}
