import { MainContainer } from '@components/ReferralBot/MainContainer'
import { rootRoute } from '@ui/routing/RootRouteIndex'
import { Route } from '@tanstack/react-router'
import {
  ownCampaignAddBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignConnectBotRoute,
  ownCampaignListBotRoute,
  ownCampaignStatusRoute,
  ownCampaignStartRoute,
  ownCampaignFinishRoute,
  ownCampaignFundRoute,
} from '@components/ReferralBot/OwnCampaign/router'
import {
  joinedCampaignDetailsRoute,
  joinedCampaignListBotRoute,
  joinedCampaignWithdrawRewardRoute,
} from '@components/ReferralBot/JoinedCampaign/router'
import { paymentBalanceBotRoute, paymentWithdrawBalanceBotRoute } from '@components/ReferralBot/Payment/router'

export const referralBotRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/referral-bot',
  component: MainContainer,
})
referralBotRoute.addChildren([
  ownCampaignListBotRoute,
  ownCampaignAddBotRoute,
  ownCampaignEditBotRoute,
  ownCampaignConnectBotRoute,
  ownCampaignStatusRoute,
  joinedCampaignListBotRoute,
  joinedCampaignDetailsRoute,
  ownCampaignStartRoute,
  ownCampaignFinishRoute,
  joinedCampaignWithdrawRewardRoute,
  ownCampaignFundRoute,
  paymentBalanceBotRoute,
  paymentWithdrawBalanceBotRoute,
])
