import { RootRoute, Route } from '@tanstack/react-router'
import { LayoutContainer } from '@ui/containers/Layout/LayoutContainer'
import { HomeContainer } from '@ui/components/Home/HomeContainer'

export const rootRoute = new RootRoute({
  component: LayoutContainer,
})

export const rootRouteIndex = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <HomeContainer />,
})
