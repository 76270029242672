import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'

export const Status = () => {
  const { uuid } = useParams()
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.ownCampaignStatus.title') || '')

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      <StatusTable uuid={uuid} />
    </>
  )
}
