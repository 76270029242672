import React from 'react'

import Box from '@mui/material/Box'

interface Props {
  children?: React.ReactNode
}

export const TopActions = ({ children }: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex="1"
      pb={3}
    >
      {children}
    </Box>
  )
}
