import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { t } from 'i18next'

import { FormHelperText, Grid, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import CelebrationIcon from '@mui/icons-material/Celebration'

const validationSchema = z.object({
  authorName: z.string().min(1).max(50),
  providedDescription: z.string().min(40).max(400),
})

export type NewGreetingType = z.infer<typeof validationSchema>

interface Props {
  onSave: (newGreeting: NewGreetingType) => void
  loading: boolean
}

export function LocationForm({ onSave, loading }: Props) {
  const defaultData: NewGreetingType = {
    providedDescription: '',
    authorName: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewGreetingType>({
    defaultValues: defaultData,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      id="create-congrats-form"
    >
      <Grid
        container
        sx={{ pt: 5 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Controller
            name="authorName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="authorName"
                label={t('createGreetingForm.authorName')}
                variant="outlined"
                error={!!errors.authorName}
                helperText={String(errors.authorName?.message || '')}
                disabled={loading}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="providedDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                rows={5}
                id="providedDescription"
                label={t('createGreetingForm.providedDescription')}
                variant="outlined"
                error={!!errors.providedDescription}
                helperText={String(errors.providedDescription?.message || '')}
                disabled={loading}
              />
            )}
          />
          <FormHelperText>{t('createGreetingForm.providedDescriptionHelpText')}</FormHelperText>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<CelebrationIcon />}
            variant="outlined"
            type="submit"
          >
            {t('createGreetingForm.createLabel')}
          </LoadingButton>
          {/* <Button */}
          {/*  variant="outlined" */}
          {/*  type="submit" */}
          {/*  disabled={loading} */}
          {/* > */}
          {/*  {t('createGreetingForm.createLabel')} */}
          {/* </Button> */}
        </Grid>
      </Grid>
    </form>
  )
}
