import React from 'react'

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { CustomTableRow } from '@components/ReferralBot/Type/CustomTableRow'

interface Props {
  rows: CustomTableRow[]
}

export const CustomTable = ({ rows }: Props) => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ tableLayout: 'fixed' }}
          aria-label="custom pagination table"
        >
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.label}>
                <TableCell
                  component="th"
                  scope="row"
                  width="180px"
                >
                  {row.label}
                </TableCell>
                <TableCell
                  style={{ fontWeight: 'bold' }}
                  align="left"
                >
                  {row.isLink ? (
                    <a
                      href={row.val}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.valSecondary || row.val}
                    </a>
                  ) : (
                    <>{row.val}</>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
