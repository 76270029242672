import { Provider } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Settings } from 'luxon'
import { SnackbarProvider } from 'notistack'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from '@tanstack/react-router'
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LOCALES, LOCALIZATIONS } from '@framework/constants/languages'
import { HydrateAtoms, queryClient } from '@ui/store/HydrateAtoms'
import { router } from '@ui/routing/router'

function App() {
  const {
    i18n: { language },
  } = useTranslation()
  Settings.defaultLocale = language

  const theme = createTheme(
    {
      palette: {
        background: {
          default: '#F8FAFB', // todo does it affect the original project?
        },
      },
    },
    {},
    LOCALIZATIONS[language]
  )
  const locale = LOCALES[language]

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={locale}
        >
          <QueryClientProvider client={queryClient}>
            <Provider>
              <HydrateAtoms>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={5000}
                >
                  <CssBaseline />
                  <SnackbarProvider />
                  <RouterProvider
                    router={router}
                    basepath={`/${import.meta.env.BASE_URL}`}
                  />
                </SnackbarProvider>
              </HydrateAtoms>
            </Provider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default App
