import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaignBudget } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import SyncIcon from '@mui/icons-material/Sync'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { AlertMessage } from '@components/ReferralBot/Alert'

export const Fund = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const { uuid } = useParams()
  const {
    data: campaignBudget,
    isLoading: isLoadingCampaign,
    isRefetching,
    refetch: refetchCampaignBudget,
    error,
  } = useQuery({
    queryKey: ['campaign_budget', uuid],
    queryFn: () => fetchCampaignBudget(uuid),
  })

  if (error) {
    enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
  }

  useEffect(() => {
    setTitle(t('referralBot.campaignFund.title', { name: campaignBudget?.name }) || '')

    return () => setTitle('')
  }, [campaignBudget])

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaignBudget && (
        <AlertMessage
          severity="warning"
          text={t('referralBot.campaignFund.platformFeeExplanation', { platformFee: campaignBudget?.platformPercentFee })}
        />
      )}
      {campaignBudget && (
        <Box>
          <p>
            {t('referralBot.campaignFund.description')}
            <br />
            <b>{t('referralBot.campaignFund.minBudgetExplanation', { minBudget: campaignBudget.minBudgetInTON })}</b>
          </p>
        </Box>
      )}
      {!isRefetching && (
        <StatusTable
          uuid={uuid}
          pickFields={['budget', 'budgetWallet', 'fundLink']}
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        p={3}
      >
        <Button
          startIcon={<SyncIcon />}
          variant="outlined"
          color="secondary"
          onClick={() => refetchCampaignBudget()}
          disabled={isLoadingCampaign || isRefetching}
        >
          {t('referralBot.campaignFund.refresh')}
        </Button>
      </Box>
    </>
  )
}
