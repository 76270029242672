import React from 'react'

import { Box } from '@mui/material'
import { Link } from '@tanstack/react-router'
import { rootRouteIndex } from '@ui/routing/RootRouteIndex'

export const Logo = () => {
  const imgUrl = new URL('./fireworks1.png', import.meta.url).href

  return (
    <Link to={rootRouteIndex.id}>
      <Box
        sx={{
          width: '175px',
          height: '80px',
          background: `url(${imgUrl}) center center no-repeat`,
          backgroundSize: 'contain',
        }}
      >
        {' '}
      </Box>
    </Link>
  )
}
