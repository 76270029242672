import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'
import { fetchCampaignStatus } from '@framework/api/referral-bot.api'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { CampaignStatusDtoFields } from '@components/ReferralBot/Type/CampaignStatusDto'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { createStatusRows } from '../helpers/CampaignStatus'

interface Props {
  uuid: string
  pickFields?: CampaignStatusDtoFields
}

export const StatusTable = ({ uuid, pickFields }: Props) => {
  const { data: campaignStatusDto } = useQuery({
    queryKey: ['campaign_status', uuid],
    queryFn: () => fetchCampaignStatus(uuid),
  })

  const { enqueueSnackbar } = useSnackbar()
  const rows = campaignStatusDto ? createStatusRows(campaignStatusDto, pickFields) : []
  const copyLinkHandler = (link: string) => {
    return () => {
      navigator.clipboard.writeText(link)
      enqueueSnackbar(t('referralBot.copied'), { variant: 'success', autoHideDuration: 1000 })
    }
  }

  return (
    <>
      {campaignStatusDto && (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table
              aria-label="custom pagination table"
              sx={{ tableLayout: 'fixed' }}
            >
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell
                      component="th"
                      scope="row"
                      width="120px"
                    >
                      {row.label}
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 'bold' }}
                      align="left"
                    >
                      {row.copyable && (
                        <IconButton
                          aria-label="copy"
                          size="small"
                          onClick={copyLinkHandler(row.val)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      {row.isLink ? (
                        <a
                          href={row.val}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.valSecondary || row.val}
                        </a>
                      ) : (
                        <>{row.val}</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  )
}
