import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useParams } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button, Step, StepContent, StepLabel, Stepper, TextField } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaign, fetchCampaignConnectCode } from '@framework/api/referral-bot.api'
import CheckIcon from '@mui/icons-material/Check'

export const ConnectBot = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { uuid } = useParams()
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { data: campaignConnectCode } = useQuery({
    queryKey: ['campaigns', 'connect_code', uuid],
    queryFn: () => fetchCampaignConnectCode(uuid),
  })

  const handleCheckConnection = async () => {
    try {
      const refetchResult = await refetch()

      if (!refetchResult?.data?.isBotConnected) {
        enqueueSnackbar(t('referralBot.connectBot.botIsNotConnected'), { variant: 'warning' })
      }
    } catch (e) {
      enqueueSnackbar(t('error'), { variant: 'error' })
    }
  }

  // todo change it?
  const botName = '@InviteUpBot'

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaign?.isBotConnected && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.connectBot.botIsConnected')}
          </Alert>
        </Box>
      )}
      <Stepper
        activeStep={-1}
        orientation="vertical"
      >
        <Step
          key={1}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step1')}</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>{t('referralBot.connectBot.step1Explanation', { bot: botName })}</Box>
          </StepContent>
        </Step>
        <Step
          key={2}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step2')}</StepLabel>
          <StepContent hidden={false}>
            <Box sx={{ mb: 2 }}>
              {t('referralBot.connectBot.step2Explanation')}
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                label=""
                variant="outlined"
                placeholder="Loading..."
                value={campaignConnectCode?.connectCommand || ''}
                onFocus={(event) => {
                  event.target.select()
                }}
              />
            </Box>
          </StepContent>
        </Step>
        <Step
          key={3}
          expanded
          active
        >
          <StepLabel>{t('referralBot.connectBot.step3')}</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ mb: 2 }}>{t('referralBot.connectBot.step3Explanation')}</Box>
              <Box
                display="flex"
                justifyContent="center"
              >
                <LoadingButton
                  loading={isLoadingCampaign || isRefetching}
                  loadingPosition="start"
                  startIcon={<CheckCircleOutlineIcon />}
                  variant="contained"
                  color="success"
                  disabled={campaign?.isBotConnected}
                  onClick={handleCheckConnection}
                >
                  {t('referralBot.connectBot.checkConnection')}
                </LoadingButton>
              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </>
  )
}
