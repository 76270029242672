import React from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { Form, NewCampaignType } from '@components/ReferralBot/OwnCampaign/Form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, pathCampaign } from '@framework/api/referral-bot.api'
import { useParams } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getErrorMessage } from '@components/ReferralBot/helpers'

export const Edit = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { uuid } = useParams()
  const { data: campaign, isLoading } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })
  const { mutate, isLoading: isPatching } = useMutation({
    mutationKey: ['campaigns', uuid],
    mutationFn: pathCampaign,
  })
  const queryClient = useQueryClient()

  const onSave = (payload: NewCampaignType) => {
    mutate(
      { ...payload, uuid },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('referralBot.updated'), { variant: 'success' })
          await queryClient.invalidateQueries(['campaigns'])
          await queryClient.invalidateQueries(['campaigns', uuid])
        },
        onError: async (error) => {
          enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
        },
      }
    )
  }

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaign && (
        <Form
          loading={isPatching}
          onSave={onSave}
          data={{
            name: campaign.name,
            rewardPerUser: Number(campaign.rewardPerUser),
          }}
        />
      )}
    </>
  )
}
