import React from 'react'

import { Box, Grid } from '@mui/material'
import { Logo } from '@ui/components/Logo'

export const NavbarContainer = () => {
  return (
    <Grid
      container
      gap={1}
      justifyContent="center"
    >
      <Grid item>
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            pt: 2,
            pb: 4,
          }}
        >
          <Logo />
        </Box>
      </Grid>
    </Grid>
  )
}
