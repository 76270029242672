import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, startCampaign } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import CheckIcon from '@mui/icons-material/Check'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'

export const Start = () => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const queryClient = useQueryClient()
  const { uuid } = useParams()
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  const { mutate: startCampaignMutate, isLoading: isStartingCampaign } = useMutation({
    mutationFn: startCampaign,
  })

  const startCampaignClick = () => {
    setConfirmDialog(false)

    startCampaignMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.campaignsList.campaignStarted'), { variant: 'success' })
        await queryClient.invalidateQueries(['campaigns', uuid])
        await queryClient.invalidateQueries(['campaigns'])
        await refetch()
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  useEffect(() => {
    setTitle(t('referralBot.startCampaign.title', { name: campaign?.name }) || '')

    return () => setTitle('')
  }, [campaign])

  const canStartCampaign = campaign?.isBotConnected && campaign?.status === CampaignStatus.INACTIVE
  const campaignStarted = campaign?.status === CampaignStatus.ACTIVE
  const disableStartCampaignButton =
    isStartingCampaign || isLoadingCampaign || isRefetching || (campaign && Number(campaign.budget) < campaign.minBudget)

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaignStarted && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.startCampaign.campaignStarted')}
          </Alert>
        </Box>
      )}
      {!isRefetching && (
        <StatusTable
          uuid={uuid}
          pickFields={['budget', 'rewardPerUser', 'joinLink']}
        />
      )}
      {campaignStarted && (
        <Box
          mt={2}
          mb={2}
        >
          <h3>{t('referralBot.startCampaign.whatIsNextTitle')}</h3>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep1') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep2') || '' }} />
          </p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: t('referralBot.startCampaign.nextStep3') || '' }} />
          </p>
        </Box>
      )}
      {canStartCampaign && (
        <>
          <Box
            mt={2}
            mb={2}
          >
            <Alert severity="info">{t('referralBot.startCampaign.expalanation')}</Alert>
          </Box>
          <Box
            sx={{ p: 2 }}
            display="flex"
            justifyContent="center"
          >
            <LoadingButton
              loading={isLoadingCampaign || isRefetching || isStartingCampaign}
              loadingPosition="start"
              startIcon={<RocketLaunchIcon />}
              variant="contained"
              color="success"
              onClick={() => setConfirmDialog(true)}
              disabled={Boolean(disableStartCampaignButton)}
            >
              {t('referralBot.startCampaign.startAction')}
            </LoadingButton>
          </Box>
        </>
      )}
      {confirmDialog && (
        <ConfirmDialog
          title={t('referralBot.campaignsList.confirmStart') || undefined}
          handleConfirm={() => startCampaignClick()}
          handleCancel={() => setConfirmDialog(false)}
        />
      )}
    </>
  )
}
