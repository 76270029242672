import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai/index'

import { useParams } from '@tanstack/react-router'
import { ownCampaignListBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { TopActions } from '@components/ReferralBot/TopActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchCampaign, finishCampaign } from '@framework/api/referral-bot.api'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { StatusTable } from '@components/ReferralBot/OwnCampaign/StatusTable'
import { getErrorMessage } from '@components/ReferralBot/helpers'
import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import CheckIcon from '@mui/icons-material/Check'
import DoneIcon from '@mui/icons-material/Done'
import { ConfirmDialog } from '@components/ReferralBot/Confirm'

export const Finish = () => {
  const [confirmDialog, setConfirmDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [, setTitle] = useAtom(headerTitleAtom)
  const queryClient = useQueryClient()
  const { uuid } = useParams()
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isRefetching,
  } = useQuery({
    queryKey: ['campaigns', uuid],
    queryFn: () => fetchCampaign(uuid),
  })

  const { mutate: finishCampaignMutate, isLoading: isFinishingCampaign } = useMutation({
    mutationFn: finishCampaign,
  })

  const finishCampaignClick = () => {
    setConfirmDialog(false)

    finishCampaignMutate(uuid, {
      onSuccess: async () => {
        enqueueSnackbar(t('referralBot.finishCampaign.campaignFinished'), { variant: 'success' })
        await queryClient.invalidateQueries(['campaigns', uuid])
        await queryClient.invalidateQueries(['campaigns'])
      },
      onError: async (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      },
    })
  }

  useEffect(() => {
    setTitle(t('referralBot.finishCampaign.title', { name: campaign?.name }) || '')

    return () => setTitle('')
  }, [campaign])

  const canFinishCampaign = campaign?.status === CampaignStatus.ACTIVE
  const campaignFinished = campaign?.status === CampaignStatus.FINISHED

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<ArrowBackIcon />}
          to={ownCampaignListBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.back')}
        </Button>
      </TopActions>
      {campaignFinished && (
        <Box
          mt={2}
          mb={2}
        >
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {t('referralBot.finishCampaign.campaignFinished')}
          </Alert>
        </Box>
      )}
      <StatusTable
        uuid={uuid}
        pickFields={['budget', 'rewardPerUser', 'status', 'startedAt', 'numberOfReferrers', 'numberOfReferrals']}
      />
      <Box
        mt={2}
        mb={2}
      >
        <p>{t('referralBot.finishCampaign.explanation')}</p>
      </Box>
      {canFinishCampaign && (
        <Box
          sx={{ p: 2 }}
          display="flex"
          justifyContent="center"
        >
          <LoadingButton
            loading={isLoadingCampaign || isRefetching}
            loadingPosition="start"
            startIcon={<DoneIcon />}
            variant="contained"
            color="success"
            disabled={isFinishingCampaign}
            onClick={() => setConfirmDialog(true)}
          >
            {t('referralBot.finishCampaign.finishAction')}
          </LoadingButton>
        </Box>
      )}
      {confirmDialog && (
        <ConfirmDialog
          title={t('referralBot.finishCampaign.confirmFinish') || undefined}
          handleConfirm={() => finishCampaignClick()}
          handleCancel={() => setConfirmDialog(false)}
        />
      )}
    </>
  )
}
