import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'
import { t } from 'i18next'

import { Grid, InputAdornment, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import CheckIcon from '@mui/icons-material/Check'
import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'

const validationSchema = z.object({
  name: z.string().min(1).max(50),
  rewardPerUser: z.coerce.number().min(0.01).max(0.5),
})

export type NewCampaignType = z.infer<typeof validationSchema>

interface Props {
  onSave: (newGreeting: NewCampaignType) => void
  loading: boolean
  data?: NewCampaignType
}

export function Form({ onSave, loading, data }: Props) {
  const defaultData: NewCampaignType = data || {
    rewardPerUser: 0.01,
    name: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewCampaignType>({
    defaultValues: defaultData,
    resolver: zodResolver(validationSchema),
  })

  if (Object.keys(errors).length > 0) {
    console.debug('Validation errors: ', errors)
  }

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      id="create-own-campaign"
    >
      <Grid
        container
        sx={{ pt: 5 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="name"
                label={t('referralBot.newOwnCampaignForm.name')}
                variant="outlined"
                error={!!errors.name}
                helperText={String(errors.name?.message || '')}
                disabled={loading}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="rewardPerUser"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{CURRENCY_SYMBOL}</InputAdornment>,
                }}
                id="rewardPerUser"
                label={t('referralBot.newOwnCampaignForm.rewardPerUser')}
                variant="outlined"
                error={!!errors.rewardPerUser}
                helperText={String(errors.rewardPerUser?.message || '')}
                disabled={loading}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<CheckIcon />}
            variant="outlined"
            type="submit"
          >
            {t(`referralBot.newOwnCampaignForm.${data ? 'update' : 'create'}`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
