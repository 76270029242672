import React from 'react'

import { Outlet, useRouter } from '@tanstack/react-router'
import { Grid } from '@mui/material'
import { useWindowsSize } from '@framework/hooks'
import { NavbarContainer } from '@ui/containers/Navbar/NavbarContainer'
import { Footer } from '@components/Footer/Index'

export function LayoutContainer() {
  const { width } = useWindowsSize()
  const router = useRouter()
  const currentPath = router.state.currentLocation.pathname
  const isReferralBot = currentPath.includes('/referral-bot')

  return (
    <>
      <Grid
        container
        maxWidth="xl"
        style={{ maxWidth: width }}
      >
        {!isReferralBot && <NavbarContainer />}
        <Outlet />
      </Grid>
      {!isReferralBot && <Footer />}
    </>
  )
}
