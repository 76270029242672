import { t } from 'i18next'

import { CampaignStatus } from '@components/ReferralBot/Type/CampaignStatus'
import { CampaignStatusDto, CampaignStatusDtoFields } from '@components/ReferralBot/Type/CampaignStatusDto'
import { CampaignStatusRow } from '@components/ReferralBot/Type/CampaignStatusRow'
import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'
import { formatDateTime } from '@components/ReferralBot/helpers/date.helper'
import { formatCurrency } from '@components/ReferralBot/helpers/index'

export const statusToTranslatableKey = (status: CampaignStatus): string => {
  switch (status) {
    case CampaignStatus.INACTIVE:
      return 'referralBot.campaignStatus.inactive'
    case CampaignStatus.ACTIVE:
      return 'referralBot.campaignStatus.active'
    case CampaignStatus.FINISHED:
      return 'referralBot.campaignStatus.finished'
    default:
      return ''
  }
}

export const createStatusRows = (campaignStatusDto: CampaignStatusDto, fields?: CampaignStatusDtoFields): Array<CampaignStatusRow> => {
  const rows: CampaignStatusRow[] = [
    {
      label: t('referralBot.ownCampaignStatus.name'),
      val: campaignStatusDto.name,
      key: 'name',
    },
    {
      label: t('referralBot.ownCampaignStatus.status'),
      val: t(statusToTranslatableKey(campaignStatusDto.status)),
      key: 'status',
    },
    {
      label: t('referralBot.ownCampaignStatus.rewardPerUser'),
      val: campaignStatusDto.rewardPerUser ? formatCurrency(campaignStatusDto.rewardPerUser.toString()) : '-',
      key: 'rewardPerUser',
    },
    {
      label: t('referralBot.ownCampaignStatus.budget'),
      val: campaignStatusDto.budget ? formatCurrency(campaignStatusDto.budget.toString()) : '-',
      key: 'budget',
    },
    {
      label: t('referralBot.ownCampaignStatus.numberOfReferrers'),
      val: String(campaignStatusDto.numberOfReferrers),
      key: 'numberOfReferrers',
    },
    {
      label: t('referralBot.ownCampaignStatus.numberOfReferrals'),
      val: String(campaignStatusDto.numberOfReferrals),
      key: 'numberOfReferrals',
    },
    {
      label: t('referralBot.ownCampaignStatus.startedAt'),
      val: campaignStatusDto.startedAt ? formatDateTime(campaignStatusDto.startedAt.toString()) : '-',
      key: 'startedAt',
    },
    {
      label: t('referralBot.ownCampaignStatus.finishedAt'),
      val: campaignStatusDto.finishedAt ? formatDateTime(campaignStatusDto.finishedAt.toString()) : '-',
      key: 'finishedAt',
    },
    {
      label: t('referralBot.ownCampaignStatus.budgetWallet'),
      val: campaignStatusDto.budgetWallet,
      key: 'budgetWallet',
      copyable: true,
    },
  ]

  if (fields && fields.includes('fundLink')) {
    rows.push({
      label: t('referralBot.ownCampaignStatus.fundLink'),
      valSecondary: t('referralBot.ownCampaignStatus.fundLinkTitle') || '',
      val: `ton://transfer/${campaignStatusDto.budgetWallet}`,
      isLink: true,
      key: 'fundLink',
    })
  }

  if (campaignStatusDto.status === CampaignStatus.ACTIVE) {
    rows.unshift({
      label: t('referralBot.ownCampaignStatus.joinLink'),
      val: campaignStatusDto.joinLink ? campaignStatusDto.joinLink : '-',
      isLink: Boolean(campaignStatusDto.joinLink),
      key: 'joinLink',
      copyable: true,
    })
  }

  if (fields) {
    return rows.filter((row) => fields.includes(row.key))
  }

  return rows
}
