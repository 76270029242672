import { t } from 'i18next'
import * as React from 'react'

import Box from '@mui/material/Box'
import { TonIcon } from '@components/Icon/TonIcon'
import Typography from '@mui/material/Typography'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useQuery } from '@tanstack/react-query'
import { fetchBalanceTransactions, fetchUserProfile } from '@framework/api/referral-bot.api'
import { Alert, Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import { paymentWithdrawBalanceBotRoute } from '@components/ReferralBot/Payment/router'
import { Transactions } from '@components/ReferralBot/Payment/Transactions'
import { CURRENCY_SYMBOL } from '@components/ReferralBot/Constant'
import { formatCurrency } from '@components/ReferralBot/helpers'

export const Balance = () => {
  // todo replace it with a list query
  const { data: userProfile, isLoading } = useQuery({
    queryKey: ['user_profile'],
    queryFn: () => fetchUserProfile(),
  })

  const { data: balanceTransactions, isLoading: isLoadingTransactions } = useQuery({
    queryKey: ['balance_transactions'],
    queryFn: () => fetchBalanceTransactions(),
  })

  const insufficientBalance = userProfile && Number(userProfile.availableBalance) < Number(userProfile.minWithdrawAmount)
  const disableWithdrawButton = !userProfile?.availableBalance || insufficientBalance

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          pb: 3,
        }}
      >
        <TonIcon sx={{ width: '60px', height: '60px' }} />
        <Typography
          p={2}
          variant="h4"
        >
          {isLoading ? '...' : formatCurrency(userProfile?.availableBalance || '0')}
        </Typography>
        <Button
          component={LinkButton}
          startIcon={<ArrowUpwardIcon />}
          variant="text"
          color="primary"
          to={paymentWithdrawBalanceBotRoute.id}
          disabled={disableWithdrawButton}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          }}
        >
          {t('referralBot.paymentBalance.withdrawBalance')}
        </Button>
        {insufficientBalance && (
          <Alert severity="info">
            {t('referralBot.paymentBalance.insufficientBalance', { minBalance: formatCurrency(userProfile?.minWithdrawAmount) })}
          </Alert>
        )}
      </Box>
      <Box>{balanceTransactions && <Transactions rows={balanceTransactions} />}</Box>
    </>
  )
}
