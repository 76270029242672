import React from 'react'

import { Box } from '@mui/material'
import { CreateGreetingContainer } from '@ui/components/Greeting/Create/CreateGreetingContainer'
import { Background } from '@components/Home/Background'

export const HomeContainer = () => {
  return (
    <>
      <CreateGreetingContainer />
      <Box
        sx={{
          textAlign: 'center',
          p: 4,
          background: 'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(99,9,121,1) 35%, rgba(0,212,255,1) 100%)',
          width: '100%',
          height: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Background />
      </Box>
    </>
  )
}
