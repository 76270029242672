import React from 'react'
import { t } from 'i18next'
import { useAtom } from 'jotai'

import { ownCampaignAddBotRoute } from '@components/ReferralBot/OwnCampaign/router'
import { ListAsTable } from '@components/ReferralBot/OwnCampaign/ListAsTable'
import { useQuery } from '@tanstack/react-query'
import { fetchCampaigns } from '@framework/api/referral-bot.api'
import { Button } from '@mui/material'
import { LinkButton } from '@components/LinkButton'
import AddIcon from '@mui/icons-material/Add'
import { TopActions } from '@components/ReferralBot/TopActions'
import { headerTitleAtom } from '@components/ReferralBot/State/HeaderTitle'
import { AlertMessage } from '@components/ReferralBot/Alert'

export const List = () => {
  const [, setTitle] = useAtom(headerTitleAtom)
  setTitle(t('referralBot.ownCampaigns.title') || '')

  const { data, isLoading } = useQuery({
    queryKey: ['campaigns'],
    queryFn: () => fetchCampaigns(),
  })

  const campaigns = data || []

  return (
    <>
      <TopActions>
        <Button
          component={LinkButton}
          startIcon={<AddIcon />}
          to={ownCampaignAddBotRoute.id}
          variant="outlined"
          color="primary"
        >
          {t('referralBot.addNew')}
        </Button>
      </TopActions>
      {!isLoading && campaigns.length === 0 && <AlertMessage text={t('referralBot.ownCampaigns.noCampaigns')} />}
      {campaigns.length > 0 && <ListAsTable campaigns={campaigns} />}
    </>
  )
}
