import * as React from 'react'

import { TonIcon } from '@components/Icon/TonIcon'
import { Chip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { fetchUserProfile } from '@framework/api/referral-bot.api'
import { Link } from '@tanstack/react-router'
import { paymentBalanceBotRoute } from '@components/ReferralBot/Payment/router'

export const BalanceHeader = () => {
  const { data: userProfile, isLoading } = useQuery({
    queryKey: ['user_profile'],
    queryFn: () => fetchUserProfile(),
  })

  const label = isLoading ? '...' : userProfile?.availableBalance

  return (
    <Link to={paymentBalanceBotRoute.id}>
      <Chip
        clickable
        variant="filled"
        color="info"
        icon={<TonIcon sx={{ width: '30px', height: '30px' }} />}
        label={label}
        sx={{
          padding: '0 5px 0 0',
          '& .MuiChip-label': {
            overflow: 'visible',
            fontWeight: 700,
          },
        }}
      />
    </Link>
  )
}
